@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: Arial, sans-serif;
}

/* * redesign btn shadow */
.btn-shadow {
    @apply shadow-[0_2px_5px_0_rgba(0,0,0,0.16),0_2px_10px_0_rgba(0,0,0,0.12)];
}

/* * creatable select */
.create-select {
    color: #5F5F5F !important;
    font-size: 14px;
    [class$="ValueContainer"] {
        min-height: 30px !important;
        max-height: 30px;
    }
    [class$="IndicatorsContainer"] {
        min-height: 30px !important;
        max-height: 30px;
    }
    [class$="ValueContainer"] {
        min-height: 30px !important;
        max-height: 30px;
    }
    [class$="-singleValue"] {
        color: #5F5F5F !important;
    }
    /* [class$="-Input"] {
        min-height: 30px !important;
        max-height: 30px;
        padding: 0px;
    } */
    [class$="-control"] {
        min-height: 30px !important;
        max-height: 30px;
    }
}