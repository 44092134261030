/* Provide sufficient contrast against white background */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Noto Sans Thai', sans-serif;
}

p[data-f-id] {
    display: none !important;
}

.swal2-container {
    z-index: 1500 !important;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: inline-block;
    vertical-align: middle;
}

a {
    color: #0366d6;
}

code {
    color: #e01a76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.trapezoid {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
}

.trapezoid.active {
    height: 30px;
    box-shadow: 0 5px 5px -5px #333;
}

.trapezoid-purple {
    border-bottom: 32px solid #a665af;
}

.trapezoid-blue {
    border-bottom: 32px solid #3d5a80;
}

.trapezoid > span {
    display: inline-block;
    color: white;
    font-size: 14px;
    /* font-weight: bold; */
    padding: 8px 0px 8px 8px;
}

.trapezoid-head {
    border-bottom: 32px solid #3397c3;
    border-right: 25px solid transparent;
    height: 0;
    width: 200px;
    color: white;
    margin-top: 8px;
    text-align: center;
}

.trapezoid-head > span {
    display: inline-block;
    color: white;
    font-size: 14px;
    padding: 8px 8px 8px 8px;
}

.mat-box {
    border-radius: 0px 5px 5px 5px;
    border: 1px solid;
    border-top-color: currentcolor;
    border-right-color: currentcolor;
    border-bottom-color: currentcolor;
    border-left-color: currentcolor;
    border-color: #e5e6e9 #dfe0e4 #d0d1d5;
    background: #fff;
    padding: 15px;
    margin-bottom: 10px;
}

.master-data-header {
    background: #fff;
    height: 57px;
    overflow: hidden;
    padding: 7px 20px;
    border-bottom: 1px solid #ddd;
    position: relative;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    width: 200px;
    color: white;
    text-align: center;
    background-color: #a665af;
    font-family: sans-serif;
    width: auto;
}

/*.nav-tabs {
    border-bottom: none;
}

    .nav-tabs .nav-link:hover {
        color: gray
    }*/

.rdw-list-dropdown,
.rdw-text-align-dropdown {
    z-index: 0 !important;
}
