.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgb(247 247 247 / 83%);
    z-index: 999;
    top: 0;
    width: 100vw;
    height: 100vh;
    left: 0;
}

.spinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3d5af1 transparent #3d5af1 transparent;
    border-radius: 50%;
    animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
